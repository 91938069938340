import axios from 'axios'
import store from '..'

const apiUrl = process.env.VUE_APP_API_URL

const filters = () => ({
  status: null,
  creativeGroupStartDate: null,
  CreativeGroupEndDate: null
})

const pagination = () => ({
  currentPage: 1,
  pageSize: 20, 
  total: 0,
})

const state = () => ({
  tableComponentKey: 0,
  creatives: [],
  creativesLoading: false, 
  creativeGroups: [],
  fiasObjects: [],
  filters: filters(),
  pagination: pagination(),
  chosenCreative: null
})

const getters = {
  getCreativeGroupById: (state) => (creativeGroupId) => {
    return state.creativeGroups.find((creativeGroup) => creativeGroup.creativeGroupId === creativeGroupId)
  },
}

const mutations = {
  setChosenCreative(state, creative) {
    state.chosenCreative = creative
  },
  increaseTableComponentKey(state) {
    state.tableComponentKey += 1
  },
  setPagination(state, value) {
    state.pagination = value
  },
  clearPagination(state) {
    state.pagination = pagination()
  },
  clearFilters(state) {
    state.filters = filters()
  },
  setFilter(state, { filterName, filterValue }) {
    state.filters[filterName] = filterValue
  },
  setFiasObjects(state, value) {
    state.fiasObjects = [...value]
  },
  setCreativesLoading(state, value) {
    state.creativesLoading = value
  },
  setCreatives(state, value) {
    state.creatives = [...value]
  },
  setCreativeGroups(state, value) {
    const uniqCreativeGroups = []

    value.map((item) => {
      const creativeGroupAlreadyAdded = uniqCreativeGroups.findIndex((creativeGroup) => {
        return creativeGroup.creativeGroupId === item.creativeGroupId
      }) >= 0

      if (!creativeGroupAlreadyAdded) {
        uniqCreativeGroups.push(item)
      }
    })

    state.creativeGroups = uniqCreativeGroups
  }
}

const actions = {
  getAllCreatives(_, data) {
    const payload = { url: "/webapi/v1/creatives/getcreatives", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  getFiasObjects(ctx, data) {
    const payload = { url: "/webapi/v1/fias/getfiasobjects", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp)
          ctx.commit('setFiasObjects', resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  getCreatives(ctx, data) {
    const payload = { url: "/webapi/v1/creatives/getcreatives", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    ctx.commit('setCreativesLoading', true)

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp.data.data)
          ctx.commit('setCreatives', resp.data.data)
          ctx.commit('setCreativeGroups', resp.data.data)
          ctx.commit('setCreativesLoading', false)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  createCreative(ctx, data) {
    const payload = { url: "/webapi/v1/creatives/createcreative", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          if(resp.data.success) {
            resolve(resp.data.data)
          } else {
            reject(resp.data.errors)
          }
          
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  bindOrdToken(ctx, data) {
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    const payload = {token:data.token, text: data.text}
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/accounts/${data.id}/ord/token`, 
      data: payload, 
      method: 'POST', 
      headers: { 'Authorization': `Bearer ${ token }` } 
    })
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        console.log(err.response)
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};