import axios from 'axios'
import store from '..'

const apiUrl = process.env.VUE_APP_API_URL

const filters = () => ({
  status: null,
  contractorInn: null,
})

const pagination = () => ({
  currentPage: 1,
  pageSize: 20, 
  total: 0,
})

const state = () => ({
  tableComponentKey: 0,
  finalContracts: [],
  allFinalContracts: [],
  finalContractsIsLoading: true,
  pagination: pagination(),
  filters: filters() 
})

const getters = {
  getFinalContractById: (state) => (id) => {
    return state.allFinalContracts.find((finalContract) => finalContract.id === id)
  },
  getFinalContracts(state) {
    return state.finalContracts
  },
  getFinalContractsIsLoading(state) {
    return state.finalContractsIsLoading
  },
}

const mutations = {
  increaseTableComponentKey(state) {
    state.tableComponentKey += 1
  },
  setPagination(state, value) {
    state.pagination = value
  },
  clearPagination(state) {
    state.pagination = pagination()
  },
  clearFilters(state) {
    state.filters = filters()
  },
  setFilter(state, { filterName, filterValue }) {
    state.filters[filterName] = filterValue
  },
  addFinalContract(state, value) {
    state.finalContracts.push(value)
  },
  setFinalContracts(state, value) {
    state.finalContracts = value
  },
  setFinalContractsIsLoading(state, value) {
    state.finalContractsIsLoading = value
  },
  setAllFinalContracts(state, value) {
    state.allFinalContracts = value
  }
}

const actions = {
  fetchAllFinalContracts(ctx) {
    const payload = { url: "/webapi/v1/contracts/getfinalcontracts", data: {} }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp.data.data)
          ctx.commit('setAllFinalContracts', resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  getFinalContracts(ctx, data) {
    const payload = { url: "/webapi/v1/contracts/getfinalcontracts", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')

    ctx.commit('setFinalContractsIsLoading', true)

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp.data.data)
          ctx.commit('setFinalContracts', resp.data.data)
          ctx.commit('setFinalContractsIsLoading', false)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  createFinalContract(ctx, data) {
    const payload = { url: "/webapi/v1/contracts/createfinalcontract", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          if(resp.data.success) {
            resolve(resp)
            ctx.commit('addFinalContract', resp.data.data)
          } else {
            reject(resp.data.errors)
          }
         
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};