<template>
  <div v-loading="finalContractsIsLoading" class="contracts">
    <el-table 
      v-if="getClientId && (!finalContractsIsLoading && getFinalContracts.length || finalContractsIsLoading && hasActiveFilter || !finalContractsIsLoading && hasActiveFilter)"
      :data="getFinalContracts"
      style="width: 100%; min-width: 700px;"
      class="contracts__table"
      stripe
      @current-change="toDetail"
    >
      <el-table-column>
        <template slot="header">
          <TableHeaderFilterSelector class="contracts__table-header-cell-filter" :isOpen="!!filters.status">
            <template #content>
              <div>Статус</div>
            </template>
            <template #options>
              <FloatSelector 
                class="contracts__table-filter"
                required
                :options="Object.keys(status).map((key) => ({id: key, name: status[key]}))"
                :select-fitrst-item="false"
                :default-select-item-id="filters.status"
                :popover-width="220"
                @handleChange="(val) => setFilter('status', val)"
              >
                <template #icons>
                  <img
                    v-if="filters.status"
                    src="@/assets/icons/icon-close.svg"
                    class="contracts__table-filter-icon"
                    @click="$event.stopPropagation(); setFilter('status', null)"
                  >
                </template>
              </FloatSelector>
            </template>
          </TableHeaderFilterSelector>
          
        </template>
        <template slot-scope="scope">
          <div class="contracts__table-cell">
            <div 
              class="contracts__table-status"
              :class="{
                'contracts__table-status_active': scope.row.status === StatusType.ACTIVE,
                'contracts__table-status_registration-required': scope.row.status === StatusType.REGISTRATION_REQUIRED,
                'contracts__table-status_registration-error': scope.row.status === StatusType.REGISTRATION_ERROR,
                'contracts__table-status_registering': scope.row.status === StatusType.REGISTERING,
              }"
            >{{ status[scope.row.status] }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="contracts__table-header-cell">
            <div>№ доходного</div>  
            <div>договора / ДС</div>  
          </div>  
        </template>
        <template slot-scope="scope">
          <div class="contracts__table-cell">
            <b>{{ scope.row.number }}</b>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="contracts__table-header-cell">
            <div>Дата заключения договора</div>  
          </div>  
        </template>
        <template slot-scope="scope">
          <div class="contracts__table-cell">{{ fixDateFormatWithDots(scope.row.date) }}</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="contracts__table-header-cell">
            <div>Тип договора</div>  
          </div>  
        </template>
        <template slot-scope="scope">
          <div class="contracts__table-cell">
            {{ ContractType[scope.row.type] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div 
      v-if="!getClientId && !finalContractsIsLoading && !hasActiveFilter" 
      class="contracts__placeholder"
    >
      <div class="contracts__placeholder-image-wrapper">
        <img 
          class="contracts__placeholder-image" 
          :src="getPlaceholderPicture" 
          alt="img" 
          title="img"
        >
      </div>
      <div class="contracts__placeholder-title">
        Для создания доходного договора выберите заказчика на вкладке «Заказчики»
      </div>
      <ButtonElement 
        class="contracts__placeholder-button" 
        :text="'Выберите заказчика'"
        @click-button="goToClientsPage" 
      />
    </div>
   
    <div class="contracts__pagination">
      <el-pagination
        v-if="pagination && pagination.total >= pagination.pageSize && !finalContractsIsLoading && getClientId"
        layout="prev, pager, next"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :current-page="pagination.currentPage"
        @current-change="setPagination({ ...pagination, currentPage: $event })"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import { mapState, mapMutations } from "vuex"
import { status, StatusType, Tabs } from "@/utils/clients"
import { ContractType } from "@/utils/contracts"
import { addQuery } from "@/utils/helpers"
import fixDateFormat from '@/mixin/fixDate'
import FloatSelector from "@/components/sort/FloatSelector";
import { debounce, clearQuery } from "@/utils/helpers"
import TableHeaderFilterSelector from "@/components/sort/TableHeaderFilterSelector";

export default {
  name: 'contracts',
  mixins: [fixDateFormat],
  components: {
    ButtonElement,
    FloatSelector,
    TableHeaderFilterSelector,
  },
  data() {
    return {
      ContractType,
      status,
      StatusType,
    }
  },
  computed: {
    ...mapState('Contracts', [
      'tableComponentKey', 
      'finalContracts', 
      'finalContractsIsLoading', 
      'filters', 
      'pagination'
    ]),
    getFinalContracts() {
      const finalContracts = [...this.finalContracts]
      return finalContracts.splice(
        (this.pagination.currentPage - 1) * this.pagination.pageSize, 
        this.pagination.currentPage * this.pagination.pageSize
      )
    },
    getPlaceholderPicture() {
      return require(`@/assets/images/accounts-default.svg`)
    },
    getClientId() {
      return this.$route.query.clientId
    },
    hasActiveFilter() {
      return Object.values(this.filters).some((filterValue) => filterValue)
    }
  },
  methods: {
    ...mapMutations('Contracts', [
      'increaseTableComponentKey', 
      'clearFilters', 
      'setPagination',
      'clearPagination'
    ]),
    toDetail(item) {
      clearQuery('clientId', this.$route.query, this.$router)
      clearQuery('initialContractId', this.$route.query, this.$router)

      addQuery('view', Tabs.CREATIVES, this.$route.query, this.$router)
      addQuery('clientId', item.clientId, this.$route.query, this.$router)
      addQuery('finalContractId', item.id, this.$route.query, this.$router)
    },
    goToClientsPage() {
      this.$router.push({ query: {
        view: Tabs.CLIENTS
      }})
    },
    fetchFinalContracts() {
      this.$store.dispatch('Contracts/getFinalContracts', {
        ...this.filters,
        ...(this.getClientId && { clientId: this.getClientId })
      }).then(finalContracts => {
        this.increaseTableComponentKey()

        this.setPagination({
          pageSize: 20,
          total: finalContracts.length,
          currentPage: 1,
        })
      })
    },
    setFilter(filterName, filterValue) {
      this.$store.commit('Contracts/setFilter', {
        filterName,
        filterValue
      })
      this.fetchFinalContracts()
    },
  },
  mounted() {
    this.fetchFinalContracts()
  },
  created() {
    this.debounceFilterInn = debounce(this.setFilter, 300)
    // clearQuery('finalContractId', this.$route.query, this.$router)
    // clearQuery('initialContractId', this.$route.query, this.$router)
  },
  destroyed() {
    this.clearFilters()  
    this.clearPagination()
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.contracts {
  overflow: auto;
  
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }
  &__table {
    &-header-cell-filter {
      height: 100%;
      min-height: 5rem;
    }

    &-header-cell {
      background: $gray8;
      color: $gray2;
      padding: .9rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      border-radius: .4rem;
      min-height: inherit;
      height: 100%;
      min-height: 5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &-filter {
      margin-top: 1rem;
    }

    &-filter-icon {
      cursor: pointer;
      z-index: 1;
      width: 1.6rem;
      height: 1.6rem;
    }

    &-cell {
      padding: 0 20px;
    }
    
    &-status {
      border-radius: .4rem;
      padding: .2rem .8rem;
      font-size: 1.4rem;
      color: #646D79;
      width: max-content;

      &_active {
        color: $green2;
        background: $green3;
      }
      &_registration-required {
        color: $blue2;
        background: $blue3;
      }
      &_registration-error {
        color: $redClassic;
        background: $red2;
      }
      &_registering {
        background: $primary;
        color: $basicWhite;
      }
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8.2rem;

    &-button {
      padding: 0 6.8rem;
    }

    &-image {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 6.4rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.3rem;
      text-align: center;
      color: $basicBlack;
      margin-bottom: 5.4rem;
      max-width: 43rem;
    }
  }
}
</style>

<style lang="scss">
.contracts__table .el-table__row  {
  cursor: pointer;
}

.contracts__table .el-table__header-wrapper th.el-table__cell {
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px;
}

.contracts__table .el-table__body-wrapper tr td {
  text-align: left;
}

.contracts {
  &__table {
    &-filter .float-selector__value {
      color: var(--black);
    }
    &-filter .float-selector__title {
      font-size: 1.6rem;
    }
    &-filter .selector__content {
      width: 100%;
    }
  }
}
</style>