export function debounce(func, wait) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function getRandomInteger(min = 1000, max = 10000) {
  return Math.floor(Math.random() * (max - min) ) + min;
}

export async function clearQuery(queryName, queries, $router) {
  let query = Object.assign({}, queries);
  delete query[queryName]
  try {
    await $router.replace({ query }).catch(()=>{});
  } catch(error) {
    console.error(error)
  }
}

export async function addQuery(queryName, queryValue, queries, $router) {
  let query = Object.assign({}, queries);
  query[queryName] = queryValue
  try {
    await $router.replace({ query })
  } catch(error) {
    console.error(error)
  }
}

export async function changeQuery(queryName, queryValue, queries, $router) {
  $router.push({ query: { 
    ...queries, 
    [queryName]: queryValue
  }})
}
export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const copyText = (text, successCallback) => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text)
      .then(successCallback)
      .catch((err) => console.error(err.name, err.message));
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const success = document.execCommand('copy');

      if (success) {
        successCallback()
      }
    } catch (err) {
      console.error(err.name, err.message);
    }
    document.body.removeChild(textArea);
  }
}

export const getRepresentativeAccess = (permissionToCheck, businessAccountInfo, role) => {
  if(role === 'partner' && businessAccountInfo && businessAccountInfo.permissions.length) {
    return businessAccountInfo.permissions.includes(permissionToCheck)
  } else {
    return false
  }
}